<template>
    <v-container fluid>
        <v-data-table
            :headers="headers"
            :items="materialsFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">SPECIAL MATERIALS</h1>
                        <v-btn
                            rounded
                            color="primary"
                            @click="openCreateMaterial"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.material`]="{ header }">
                <v-text-field
                    class="pb-2 pt-3 mt-2"
                    :label="header.text"
                    v-model="materialToFilter"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.name`]="{ item }">
                <p class="mb-0">
                    {{ processes.find(i => i.id == item.process)?.name }}
                </p>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openDeleteMaterial(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!--PROCESSES FORM-->
        <v-dialog
            v-model="openMaterialForm"
            :retain-focus="false"
            persistent
            width="600px"
        >
            <SpecialMatForm
                v-if="openMaterialForm"
                :createForm="createForm"
                :originalMaterial="selectedMaterial"
                :settingId="setting.id"
                :items="items"
                @addMaterial="addMaterial"
                @replaceMaterial="replaceMaterial"
                @close="closeEditMaterial"
            />
        </v-dialog>
        <!--Delete Material-->
        <v-dialog
            v-model="openDeleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openDeleteWarning">
                <v-card-title class="text-h5">Delete Material</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this material?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteMaterial">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteMaterial"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'
import { sassTrue } from 'sass'

export default {
    name: 'SpecialMaterials',
    props: {
        setting: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        SpecialMatForm: () =>
            import('@/components/SpecialMaterials/SpecialMatForm.vue'),
    },
    data: () => ({
        loading: false,
        selectedMaterial: {},
        originalMaterial: {},
        createForm: true,
        openMaterialForm: false,
        openDeleteWarning: false,
        settings: {},
        headers: [
            {
                text: 'MATERIAL',
                value: 'material',
                sortable: false,
                width: 100,
            },
            {
                text: 'DESCRIPTION',
                value: 'short_material_text',
                sortable: false,
                width: 100,
            },
            {
                text: 'NOTES',
                value: 'notes',
                sortable: false,
                width: 100,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                sortable: false,
                align: 'center',
                width: 100,
            },
        ],
        materialToFilter: undefined,
        items: [],
    }),
    async created() {
        try {
            this.loading = sassTrue
            if (!this.setting) {
                const {
                    data: { settings },
                } = await API.getSettings()

                this.settings = settings
                this.setting = this.settings.find(
                    setting => setting.name == 'SpecialMaterials'
                )
            }

            this.getStock()
        } catch (error) {
            this.setErrorItems({
                name: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        materialsFiltered() {
            const conditions = []
            if (this.materialToFilter) {
                conditions.push(this.filterForMaterial)
            }
            if (conditions.length > 0) {
                return this.setting.materials.filter(material => {
                    return conditions.every(condition => {
                        return condition(material)
                    })
                })
            }
            return this.setting.materials
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterForMaterial(item) {
            return item.material
                .toString()
                .includes(this.materialToFilter.toLowerCase())
        },
        replaceMaterial(materialToUpdate) {
            const index = this.setting.materials.findIndex(
                material => material.id == materialToUpdate.id
            )
            if (index >= 0) {
                this.setting.materials.splice(index, 1, {
                    ...this.materials[index],
                    ...materialToUpdate,
                })
            }
        },
        addMaterial(material) {
            if (material && this.setting.materials) {
                this.setting.materials = [...this.setting.materials, material]
            }
        },
        removeMaterial(material) {
            if (material) {
                const index = this.setting.materials.findIndex(
                    item => item.material == this.selectedMaterial.material
                )
                if (index >= 0) {
                    this.setting.materials.splice(index, 1)
                }
            }
        },
        async deleteMaterial() {
            try {
                this.loading = true
                const material = await API.deleteSpecialMaterial({
                    settingId: this.setting.id,
                    materialId: this.selectedMaterial.material,
                })
                this.removeMaterial(material)
                this.closeDeleteMaterial()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getStock() {
            try {
                this.loading = true
                this.items = await API.getStockCodes()
                this.items.forEach(item => {
                    item.codeAndDescription = item.description
                        ? item.description
                        : ''
                    item.material = item.material ? item.material : null
                    item.description = item.description ? item.description : ''
                    item.notas = item.notas ? item.notas : ''
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDeleteMaterial() {
            this.selectedMaterial = {}
            this.openDeleteWarning = false
        },
        openDeleteMaterial(item) {
            this.selectedMaterial = _.cloneDeep(item)
            this.openDeleteWarning = true
        },
        closeEditMaterial() {
            this.createForm = true
            this.selectedMaterial = {}
            this.originalMaterial = {}
            this.notes = ''
            this.openMaterialForm = false
        },
        openCreateMaterial() {
            this.createForm = true
            this.openMaterialForm = true
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
</style>
